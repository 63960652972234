@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&display=swap');

* {
  color: white;
  font-size: 72px;
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Aref Ruqaa Ink', serif;
  box-sizing: border-box;
}

.app {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

span {
  font-size: 48px;
}
h2 {
  font-size: 42px;
  font-weight: 400;
  margin-top: 16px;
}